import { useState } from "react";
import classes from "./AboutUs.module.scss";
import clemStrategy from "../../../../assets/icons/clemStrategy.svg";
import clemKeyOutcomes from "../../../../constants/clemKeyOutcomes";

function AboutUs() {
  const [showContent, setShowContent] = useState(false);

  return (
    <>
      {!showContent ? (
        <div
          className={classes["title-container"]}
          onClick={() => setShowContent(true)}
        >
          <div className={classes["toggle"]}>+</div>
          <div className={classes.title}>About CLEM</div>
        </div>
      ) : null}

      {showContent ? (
        <div className={classes["open-container"]}>
          <div
            className={classes["open-title-container"]}
            onClick={() => setShowContent(false)}
          >
            <div className={classes.title}>About CLEM</div>
            <div className={classes["minus"]} />
          </div>

          <div className={classes.info}>
            <div className={classes["sub-title"]}>
              Practical and Crafted Approach
            </div>
            <p className={`${classes.text} ${classes.text1}`}>
              Coal-fired power generation remains a significant contributor to
              global carbon emissions, particularly in Asia, where a
              concentration of young CFPPs exists, averaging less than 15 years
              old. To address this pressing issue, CLEM, a first-of-its-kind
              clean energy transition tailor-made solution, takes a practical
              data-driven approach working with CFPP asset owners, power
              purchasers and policymakers by connecting the dots to automate,
              simplify and scale the clean energy transitions.
            </p>

            <div className={classes["sub-title"]}>
              Vision: Making Energy Transitions Profitable
            </div>
            <p className={classes.text}>
              CLEM’s vision is to make energy transition not just practical but
              profitable for CFPP asset owners and financiers. Leveraging a
              cutting-edge, data-driven approach, CLEM’s tailor-made solutions
              provide the most effective and strategic clean energy transition
              planning while ensuring profitability with a make-it-happen
              approach.
            </p>
          </div>

          <div className={classes["strategy-img-container"]}>
            <img
              className={classes["strategy-img"]}
              src={clemStrategy}
              alt="CLEM strategy and solution"
            />
          </div>

          <div className={classes["outcomes-container"]}>
            <div className={classes["outcomes-title"]}>
              KEY OUTCOMES<span>:</span>
            </div>
            <ul className={classes["outcomes-list"]}>
              {clemKeyOutcomes.map((data, index) => (
                <li key={index}>
                  <span className={classes["outcomes-subtext"]}>
                    {data.title} -{" "}
                  </span>{" "}
                  <span className={classes["outcomes-desc"]}>
                    {data.description}
                  </span>
                </li>
              ))}

              <li style={{ listStyle: "none" }}>
                <span className={classes["outcomes-subtext"]}>
                  Given the importance of PPA amendments, we work closely with
                  stakeholders such as purchasers, policymakers, and legal
                  institutions, while leveraging inputs from CLEM modules, to
                  automate PPA amendments.
                </span>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AboutUs;
