import classes from "./VideoSection.module.scss";

const VideoSection = () => {
  return (
    <div className={classes["video-section-container"]}>
      <div className={classes["video-container"]}>
        <video
          src={
            "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/overview+video.mp4"
          }
          autoPlay
          loop
          muted
          controls={true} /* If you want to hide controls */
          width="100%"
          height="100%"
        ></video>
      </div>
    </div>
  );
};

export default VideoSection;
