import classes from "./OurAssociation.module.scss";
import Pcaf from "../../../assets/icons/PCAF.svg";
import Ggp from "../../../assets/icons/GGP.svg";
import Ifrs from "../../../assets/icons/IFRS.svg";
import Tcfd from "../../../assets/icons/TCFD.svg";
import Usec from "../../../assets/icons/US_SEC.svg";
import Efrag from "../../../assets/icons/EFRAG.svg";
import Cdp from "../../../assets/icons/CDP.svg";
import Sasb from "../../../assets/icons/SASB.svg";
import Sbt from "../../../assets/icons/SBT.svg";
import Gfanz from "../../../assets/icons/GFANZ.svg";
import Icma from "../../../assets/icons/ICMA.svg";
import ClimateBonds from "../../../assets/icons/ClimateBondsInitiative.svg";
import Asean from "../../../assets/icons/ASEAN.svg";
import Vcmi from "../../../assets/icons/VCMI.svg";
import Car from "../../../assets/icons/CAR.svg";
import Gs from "../../../assets/icons/GS.svg";
import Acg from "../../../assets/icons/ACG.svg";
import Vcs from "../../../assets/icons/VCS.svg";
import Iea from "../../../assets/icons/IEA.svg";
import Irena from "../../../assets/icons/IRENA.svg";
import Ipcc from "../../../assets/icons/IPCC.svg";
import Ngfs from "../../../assets/icons/NGFS.svg";
import { useState } from "react";

const marqueeFirstArray = [
  Pcaf,
  Ggp,
  Car,
  Tcfd,
  Iea,
  Efrag,
  Gs,
  Sasb,
  Vcmi,
  Gfanz,
  Icma,
];

const marqueeSecondArray = [
  Ngfs,
  Asean,
  Sbt,
  Ifrs,
  Irena,
  Acg,
  Vcs,
  Usec,
  Ipcc,
  Cdp,
  ClimateBonds,
];

const OurAssociation = () => {
  const [hoveredIndex, setHoveredIndex] = useState(0);

  const mouseEnterHandler = (receivedIndex: number) => () => {
    setHoveredIndex(receivedIndex);
  };

  return (
    <div className={classes["our-association-container"]}>
      <div className={classes["oac-title"]}>Global Standards</div>

      <div className={classes["marquee"]}>
        {marqueeFirstArray.map((el, index: number) => (
          <img
            key={index}
            src={el}
            className={`${classes["item-left"]} ${classes[`item-${index}`]}`}
          ></img>
        ))}
      </div>

      <div className={classes["marquee"]}>
        {marqueeSecondArray.map((el, index: number) => (
          <img
            key={index}
            src={el}
            className={`${classes["item-right"]} ${classes[`item-${index}`]}`}
          ></img>
        ))}
      </div>
    </div>
  );
};

export default OurAssociation;
