import classes from "./GreenFinSummit.module.scss";
import Card from "../../../Components/Card/Card";

import commonClasses from "../EventsCommonStyles.module.scss";

import { ReactComponent as TopWave } from "../../../assets/icons/top_wave.svg";

import useIsMobile from "../../../Hooks/useIsMobile";

function GreenFinSummit() {
  const isMobile = useIsMobile(1001);

  const cardContent = (title: string, date: string, address: string) => (
    <div className={commonClasses["card-content"]}>
      <div className={commonClasses["card-title"]}>
        <p>{title}</p>
      </div>
      <div className={commonClasses["separator"]}></div>
      <div className={commonClasses["card-info"]}>
        <p className={commonClasses["card-date"]}>{date}</p>
        <p className={commonClasses["card-address"]}>{address}</p>
      </div>
    </div>
  );

  return (
    <div className={classes["container"]}>
      <div className={commonClasses["event-info-container"]}>
        <div className={commonClasses["info"]}>
          <p className={commonClasses["title"]}>GreenFin</p>
          <p className={commonClasses["desc"]}>
            Sustainability Economics at GreenFin Boston Summit
          </p>
        </div>
        <div className={commonClasses["event-session-container"]}>
          <Card
            customClass={commonClasses["event-details"]}
            style={{ cursor: "unset" }}
          >
            {cardContent(
              "Main Event",
              "26th - 28th Jun’23",
              "Omni Boston Hotel at the Seaport, 450 Summer St, Boston, MA 02210, United States"
            )}
          </Card>
        </div>

        {!isMobile ? (
          <>
            <div className={commonClasses["top-wave-container"]}>
              <TopWave width={1300} height={520} />
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      <div className={classes["interview-container"]}>
        <div className={classes.title}>Interview at GreenFin</div>

        <p className={classes.text}>
          Hear from our CEO and Co-founder in an exclusive interview with WION
          at the GreenFin Summit in Boston.
        </p>

        <iframe
          className={classes.iframe}
          src="https://www.youtube.com/embed/AqU4n848S38?si=WLXB6PMXIiOMi_o5"
          title="CEO interview at GreenFin"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default GreenFinSummit;
