import { useState } from "react";
import classes from "./WhoWeWorkWith.module.scss";
import styles from "../../../DesignSystem/_classes.module.scss";
import useIsMobile from "../../../Hooks/useIsMobile";

let dataArr = [
  {
    title: "Financial Institutions",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/low-angle-shot-buildings-spain-clear-blue-sky.jpg",
  },
  {
    title: "Energy Sector",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/power-plant-with-smoke-coming-out-it-power-plant-background.jpg",
  },
  {
    title: "Real Estate",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/apartment-office-tall-building-construction-top-view-tower-crane-city-landscape-stretching-horizon-drone-aerial-photography.jpg",
  },
  {
    title: "Industries",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/environmental-pollution-factory-exterior.jpg",
  },
  {
    title: "Transport",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/many-transport-trucks-parked-service-station-sunset-ai-generative.jpg",
  },
  {
    title: "High Emitting Sectors",
    img: "https://se-website-image.s3.ap-south-1.amazonaws.com/low-angle-shot-factory-with-smoke-steam-coming-out-chimneys-captured-sunset.jpg",
  },
];

let descArr = [
  "Empowering banks, investors, and asset managers to integrate sustainable practices into their portfolios and financing decisions.",
  "Collaborating with energy companies to transition towards renewable sources and achieve carbon neutrality.",
  "Supporting developers and property managers in adopting green and sustainable standards and reducing their carbon footprint.",
  "Assisting manufacturing and industrial companies in implementing energy-efficient technologies and sustainable production processes.",
  "Working with transportation providers to shift towards low-emission vehicles and sustainable logistics solutions.",
  "Partnering with industries with significant carbon footprints to develop and implement strategies for substantial emission reductions.",
];

const WhoWeWorkWith = () => {
  const isMobile = useIsMobile();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const hoverHandler = (receivedIndex: number | null) => () => {
    setHoveredIndex(receivedIndex);
  };

  return (
    <div className={classes["work-with-container"]}>
      <div className={classes["wwc-title"]}>Sectors we cover</div>
      <div className={classes["wwc-separator"]}></div>
      {isMobile ? (
        <div
          className={`${classes["wwc-image-container-mobile"]} ${styles["hide-scrollbar"]}`}
        >
          {dataArr.map((el, index) => (
            <div
              className={
                hoveredIndex !== index
                  ? classes["wwc-card-mobile"]
                  : `${classes["wwc-card-mobile"]} ${classes["wwc-card-hovered-mobile"]}`
              }
              onMouseEnter={hoverHandler(index)}
              onMouseLeave={hoverHandler(null)}
            >
              {hoveredIndex !== index ? (
                <>
                  <img
                    src={el.img}
                    height="100%"
                    width="100%"
                    alt="steppe.png"
                    loading="lazy"
                  ></img>
                  <div className={classes["wwc-card-title-mobile"]}>
                    {el.title}
                  </div>
                </>
              ) : (
                <p style={{backgroundColor:"#eeeeee", height:"100%" , width:"100%" , borderRadius:"11px" , fontSize:"9px"}}>{descArr[index]}</p>
              
              )}      
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className={classes["wwc-desc"]}>
            <p>
              We partner with a diverse range of sectors such as financial
              institutions, energy sector, real estate, high emitting sectors,
              among others to drive impactful sustainability solutions
            </p>
          </div>
          <div className={classes["wwc-image-container"]}>
            {dataArr.map((el, index) => (
              <div
                className={
                  hoveredIndex !== index
                    ? classes["wwc-card"]
                    : `${classes["wwc-card"]} ${classes["wwc-card-hovered"]}`
                }
                onMouseEnter={hoverHandler(index)}
                onMouseLeave={hoverHandler(null)}
              >
                {hoveredIndex !== index ? (
                  <>
                    <img
                      src={el.img}
                      height="100%"
                      width="100%"
                      alt="steppe.png"
                      loading="lazy"
                    ></img>
                    <div className={classes["wwc-card-title"]}>{el.title}</div>
                  </>
                ) : (
                  <p>{descArr[index]}</p>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default WhoWeWorkWith;
