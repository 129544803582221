import { useEffect } from "react";
import Button from "../../../Components/Button/Button";
import useIsMobile from "../../../Hooks/useIsMobile";
import classes from "./HomePage.module.scss";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile(900);

  useEffect(() => {
    // Set the tab name
    document.title = "Net-Zero Transitions: End-to-End Lifecycle Management";

    // Set the meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Begin your Net-Zero journey with Sustainability Economics. We simplify, automate, and scale Net-Zero transitions using cutting-edge technology across sectors."
      );
    } else {
      // If the meta tag doesn't exist, create it
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Begin your Net-Zero journey with Sustainability Economics. We simplify, automate, and scale Net-Zero transitions using cutting-edge technology across sectors.";
      document.head.appendChild(meta);
    }

    // Clean up (if necessary) when component unmounts
    return () => {
      document.title = "Default Title"; // Restore the default title
    };
  }, []);

  return (
    <div className={classes["homepage-container"]}>
      <div className={classes["video-container"]}>
        <video
          src={
            "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/SE+Website+trimmed.mp4"
          }
          autoPlay
          loop
          muted
          controls={false} /* If you want to hide controls */
          width="100%"
          height="100%"
        ></video>
        <div className={classes["video-text-container"]}>
          {isMobile ? (
            <p>
              <div>Begin your</div>
              <span> Journey to Net-Zero, </span>
              <div>with us</div>
            </p>
          ) : (
            <p>
              Begin your
              <span> Journey to Net-Zero, </span>
              with us
            </p>
          )}

          <div className={classes["git-btn-container"]}>
            {isMobile ? (
              <Button
                text="Get in touch"
                btnClickHandler={() => navigate("/contact-us")}
                btnStyles={{
                  width: "104px",
                  height: "30.26px",
                  backgroundColor: "#288f90",
                  fontSize: "13px",
                  fontWeight: 300,
                }}
              />
            ) : // <Button
            //   text="Get in touch"
            //   btnClickHandler={() => navigate("/contact-us")}
            //   btnStyles={{
            //     width: "114px",
            //     height: "37.26px",
            //     backgroundColor: "#288f90",
            //     fontSize: "14px",
            //     fontWeight: 300,
            //   }}
            // />
            null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
