import Card from "../../../Components/Card/Card";
import classes from "./LinksSection.module.scss";
import { ReactComponent as ArrowWithTail } from "../../../assets/icons/arrow_with_tail.svg";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../Hooks/useIsMobile";

const LinksSection = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const navLinkClickHandler = (link: string) => () => {
    navigate(link);
  };

  return (
    <div className={classes["link-section-container"]}>
      <Card
        customClass={`${classes["grid-item"]} ${classes["about-us-card"]}`}
        extraProps={{ onClick: navLinkClickHandler("/about-us") }}
      >
        <div className={classes["card-title"]}>About Us</div>
        <div className={classes["card-separator"]}></div>
        <div className={classes["card-desc"]}>
          <p>
            Sustainability Economics leads comprehensive Net-Zero transitions in
            high-emitting sectors, using advanced technology to streamline and
            scale solutions. Our global team of experts and strategic
            partnerships ensure alignment with global standards throughout the
            transition journey to simplify, automate, and scale our solutions.
          </p>
        </div>
        <div className={classes["visit-me-icon-container"]}>
          <ArrowWithTail />
        </div>
      </Card>
      <Card customClass={`${classes["grid-item"]} ${classes["grid-item-img"]}`}>
        <img
          src={
            "https://clem-website-images-videos.s3.ap-south-1.amazonaws.com/sunny-landscape-with-windmills.jpg"
          }
          height="100%"
          width="100%"
          alt="steppe.png"
          loading="lazy"
        ></img>
      </Card>
      <Card customClass={`${classes["grid-item"]} ${classes["grid-item-img"]}`}>
        <img
          src={
            "https://se-website-image.s3.ap-south-1.amazonaws.com/aerial-shot-two-power-plant-sunset-vilnius.jpg"
          }
          height="100%"
          width="100%"
          alt="steppe.png"
          loading="lazy"
        ></img>
      </Card>
      <Card
        customClass={`${classes["grid-item"]}`}
        extraProps={{ onClick: navLinkClickHandler("/solutions") }}
      >
        <div className={classes["card-title"]}>Solutions</div>
        <div className={classes["card-separator"]}></div>
        <div className={classes["card-desc"]}>
          <p>
            Our solution ranges from providing innovative financing options and
            strategic support for effective Net-Zero transitions. Our offerings
            streamline investment, ensure compliance, and drive impactful
            results across high-emitting sectors.
          </p>
        </div>
        <div className={classes["visit-me-icon-container"]}></div>
        <div className={classes["visit-me-icon-container"]}>
          <ArrowWithTail />
        </div>
      </Card>
      <Card
        customClass={`${classes["grid-item"]}`}
        extraProps={{
          onClick: navLinkClickHandler("/resources/events-overview"),
        }}
      >
        <div className={classes["card-title"]}>Events</div>
        <div className={classes["card-separator"]}></div>
        <div className={classes["card-desc"]}>
          <p>
            We are excited to participate in Climate Week NYC 2024, where we
            will engage in discussions on energy and sustainable finance. Our
            interest in these events highlights our commitment to advancing
            innovative solutions and driving impactful change in the industry.
          </p>
        </div>
        <div className={classes["visit-me-icon-container"]}></div>
        <div className={classes["visit-me-icon-container"]}>
          <ArrowWithTail />
        </div>
      </Card>
      <Card customClass={`${classes["grid-item"]} ${classes["grid-item-img"]}`}>
        <img
          src={
            "https://se-website-image.s3.ap-south-1.amazonaws.com/CWNYC+2022+-+OC+2.jpg"
          }
          height="100%"
          width="100%"
          alt="steppe.png"
          loading="lazy"
        ></img>
      </Card>
    </div>
  );
};

export default LinksSection;
