const strategyArr = [
    {
      title: "Current State Discovery",
      desc: "Data-driven extraction of technical and financial details of the current state of the business.",
    },
    {
      title: "Transition Planning",
      desc: "Practical, location/country-specific transition plans using a phased approach, developed in collaboration with business owners, financial institutions, and policymakers.",
    },
    {
      title: "Economic Gap",
      desc: "Practical, data-driven mechanism to pinpoint the exact financial loss business owners must bear during the transition.",
    },
    {
      title: "Zero/ Low Carbon Transition",
      desc: "Model-driven approaches that consider region/country-specific policies to identify the accurate future state plan towards zero/low carbon state",
    },
    {
      title: "Transition Finance",
      desc: "Data-driven, practical methods to identify transition financing requirements, aligned with clear timelines and various business models. Construction of transition financing frameworks using innovative global financial instruments to make the transition profitable, in collaboration with global equity and debt financial partners.",
    },
    {
      title: "Transition Credits",
      desc: "Playing the role of transition credit methodology developer and project proponent",
    },
    {
      title: "Blockchain-Driven Marketplace",
      desc: "Sourcing the appropriate clean energy technologies by working with clean energy developers to accelerate the transition.",
    },
    {
      title: "Financial Analysis",
      desc: "Providing detailed financial analysis with innovative transition financing instruments and transition credit cash flows for both equity and debt players.",
    },
  ];

  export default strategyArr;