import classes from "./Dropdown.module.scss";
import { useNavigate } from "react-router-dom";

type Props = {
  containerClass?: string;
  dropdownItemClass?: string;
  parentPath: string;
  dropdownVisibility: boolean;
  seperatorClass?: string;
  setHeaderPopupVisibility?: (value: boolean) => void;
};

const resources = [
  {
    title: "Media",
    link: "https://sustainabilityeconomicsnews.com/",
  },
  {
    title: "Events",
    link: "/events-overview",
  },
  {
    title: "Press Release",
    link: "/press-release",
  },
];

function Dropdown(props: Props) {
  const {
    dropdownVisibility,
    parentPath,
    containerClass,
    dropdownItemClass,
    seperatorClass,
    setHeaderPopupVisibility,
  } = props;
  const navigate = useNavigate();

  return (
    <div
      className={`${classes["dropdown-container"]} ${
        containerClass ? containerClass : ""
      } ${dropdownVisibility ? classes["is-visible"] : ""}`}
    >
      {resources.map((item, index) => (
        <>
          <div
            key={index}
            className={`${classes["dropdown-item"]} ${
              dropdownItemClass ? dropdownItemClass : ""
            }`}
            role="button"
            onClick={() => {
              if (item.title === "Media") {
                window.open(
                  "https://sustainabilityeconomicsnews.com/",
                  "_blank"
                );
              } else {
                navigate(parentPath + item.link);
              }

              if (setHeaderPopupVisibility) setHeaderPopupVisibility(false);
            }}
          >
            {item.title}
          </div>

          {index !== resources.length - 1 && (
            <div
              className={`${classes.seperator} ${
                seperatorClass ? seperatorClass : ""
              }`}
            />
          )}
        </>
      ))}
    </div>
  );
}

export default Dropdown;
