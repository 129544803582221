import classes from "./OrnamentalImage.module.scss";

const OrnamentalImage = () => {
  return (
    <div className={classes["ornamental-image-container"]}>
      <img
        src={
          "https://se-website-image.s3.ap-south-1.amazonaws.com/red-zeppelin-UVGE-o757-g-unsplash.jpg"
        }
        height="100%"
        width="100%"
        alt="steppe.png"
        loading="lazy"
      ></img>
    </div>
  );
};

export default OrnamentalImage;
