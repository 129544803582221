import React from "react";

const arrowTypes = {
  up: {
    rotate: 180,
  },
  down: {
    rotate: 0,
  },
  left: {
    rotate: 90,
  },
  right: {
    rotate: 270,
  },
};

interface Props {
  style?: React.CSSProperties;
  color?: string;
  height?: string | number;
  width?: string | number;
  arrowType: keyof typeof arrowTypes;
}

const FilledArrow: React.FC<Props> = ({
  style,
  color = "#288F90",
  width = 7,
  height = 6,
  arrowType = "up",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 7 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${arrowTypes[arrowType].rotate}deg)`,
        ...style,
      }}
    >
      <path
        d="M3.32349 6L0.177814 0.75L6.46917 0.75L3.32349 6Z"
        fill="#288F90"
      />
    </svg>
  );
};

export default FilledArrow;
