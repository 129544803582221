import classes from "./OurStrategy.module.scss";
import { ReactComponent as Strategy } from "../../../assets/icons/strategy.svg";
import Card from "../../../Components/Card/Card";
import GetInTouch from "../../../Components/GetInTouch/GetInTouch";
import { useState } from "react";
import useIsMobile from "../../../Hooks/useIsMobile";
import OurStrategyCards from "./OurStrategyCards/OurStrategyCards";
import { strategyArr } from "../../utils/data/strategyArr";

const OurStrategy = () => {
  const isMobile = useIsMobile(900);
  const [hoveredIndex, setHoveredIndex] = useState<number>(0);

  const mouseEnterHandler = (receivedIndex: number) => () => {
    setHoveredIndex(receivedIndex);
  };

  return (
    <div className={classes["our-strategy-container"]}>
      <div className={classes["os-title-svg-container"]}>
        <div className={classes["os-title-container"]}>
          <p className={classes["os-title"]}>Our Strategy</p>
          <div className={classes["os-separator"]}></div>
          <p className={classes["os-desc"]}>
            Our data-driven, practical strategy is built on a comprehensive
            8-step approach, crafted to ensure a systematic and effective
            transition. Our approach combines in-depth analysis, stakeholder
            engagement, and expert insights to guide every phase, from initial
            planning to successful implementation.{" "}
          </p>
        </div>
        <div className={classes["os-svg-container"]}>
          <Strategy />
        </div>
      </div>
      {isMobile ? (
        <OurStrategyCards />
      ) : (
        <div className={classes["os-cards-container"]}>
          {strategyArr.map((el: any, index: number) => (
            <Card
              // customClass={classes["os-card"]}
              customClass={
                hoveredIndex === index
                  ? `${classes["os-card"]} ${classes["os-card-expanded"]}`
                  : `${classes["os-card"]} ${classes["os-card-normal"]}`
              }
              extraProps={{
                onMouseEnter: mouseEnterHandler(index),
              }}
            >
              <div className={classes["card-number-container"]}>
                {/* <div className={classes["card-number"]}>
                  {index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}
                </div> */}
              </div>
              <div className={classes["card-title"]}>
                <p>{el.title}</p>
              </div>
              {hoveredIndex === index && (
                <div className={classes["separator"]}></div>
              )}
              <div
                className={
                  index === 0 || index === 3
                    ? `${classes["card-desc"]} ${classes["card-desc-more-margin"]}`
                    : `${classes["card-desc"]}`
                }
              >
                {hoveredIndex === index && <p>{el.desc}</p>}
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default OurStrategy;
