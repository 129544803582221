import classes from "./WhoWeHelpMobile.module.scss";
import { whoWeHelpContent } from "../../utils/data/whoWeHelpContent";

const WhoWeHelpMobile = () => {

  return (
      <div className={classes["who-we-work-container"]}>
        <div className={classes.title}>Who we help</div>

        <div className={classes.divider} />

        <div className={classes["cards-container"]}>
          {whoWeHelpContent.map((card, index) => (
            <div className={classes.card} key={index}>
              <img src={card.imgSrc} alt={card.title} className={classes.img} />
              <div className={classes.title}>{card.title}</div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default WhoWeHelpMobile;