import classes from "./OurVision.module.scss";
import Comment from "../../../assets/icons/comment";
import { ReactComponent as FullComment } from "../../../assets/icons/full_comment.svg";
import useIsMobile from "../../../Hooks/useIsMobile";

let cardArr = [
  {
    title: "20+",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent ",
  },
  {
    title: "8+",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent ",
  },
  {
    title: "6+",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent ",
  },
];

const OurVision = () => {
  const isMobile = useIsMobile(700);

  return (
    <div className={classes["our-vision-section"]}>
      {isMobile ? (
        <>
          <div className={classes["our-vision-comment-container"]}>
            <p className={classes["our-vision-text"]}>Our Vision</p>
          </div>
          <div className={classes["our-vision-para"]}>
            <p className={classes["first-para"]}>
              Making <b>Net-Zero </b>
            </p>
            transitions, <span>profitable</span>.
          </div>
          <div className={classes["comment-svg-left"]}>
            <FullComment />
          </div>
        </>
      ) : (
        <>
          <div className={classes["our-vision-comment-container"]}>
            <div className={classes["comment-svg-left"]}>
              <Comment />
            </div>
            <p className={classes["our-vision-text"]}>Our Vision</p>
          </div>
          <div className={classes["our-vision-para"]}>
            <p className={classes["first-para"]}>
              Making <b>Net-Zero</b> transitions,
              <p>profitable.</p>
            </p>
          </div>
          <div className={classes["comment-svg-right"]}>
            <Comment />
          </div>
        </>
      )}
    </div>
  );
};

export default OurVision;
